import React from 'react'
import { useState } from 'react'
import { plans } from './Plans'

export default function Visiting() {

    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const todaysDate = new Date(date)
    const [futurePlans, setFuturePlans] = useState(
        plans.filter(plan => new Date(plan.date) >= todaysDate)
    )
    const [pastPlans, setPastPlans] = useState(
        plans.filter(plan => new Date(plan.date) < todaysDate)
    )


    return (
        <div className='visiting'>
            {futurePlans.map((plan) => {
                return (
                    <div key={`plan ${plan.id}`} className='visiting-box'>
                        <div className='visiting-image'>
                            <img className='img-fluid' src={plan.image} alt="place" />
                        </div>
                        <div className='visiting-info'>
                            <div className='visiting-date'>
                                {plan.dateText}
                            </div>
                            <div className='visiting-name'>
                                <a href={plan.link}><h4>{plan.title}</h4></a>
                            </div>
                            <div className='visiting-place'>
                                <div>{plan.place}</div>
                                <div>{plan.location}</div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {pastPlans.reverse().map((plan) => {
                return (
                    <div key={`plan ${plan.id}`} className='visiting-box past-plan'>
                        <div className='visiting-image'>
                            <img className='img-fluid' src={plan.image} alt="place" />
                        </div>
                        <div className='visiting-info'>
                            <div className='visiting-date'>
                                {plan.dateText}
                            </div>
                            <div className='visiting-name'>
                                <a href={plan.link}><h4>{plan.title}</h4></a>
                            </div>
                            <div className='visiting-place'>
                                <div>{plan.place}</div>
                                <div>{plan.location}</div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
