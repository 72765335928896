import icon1 from './icons/icon1.svg'
import icon2 from './icons/icon2.svg'
import icon3 from './icons/icon3.svg'
import icon4 from './icons/icon4.svg'
import icon5 from './icons/icon5.svg'
import icon6 from './icons/icon6.svg'
import icon7 from './icons/icon7.svg'
import icon8 from './icons/icon8.svg'
import icon9 from './icons/icon9.svg'
import icon10 from './icons/icon10.svg'
import icon11 from './icons/icon11.svg'
import icon12 from './icons/icon12.svg'
import icon13 from './icons/icon13.svg'

const rules = [
    {
        number: '01.',
        name: 'Take risks - be responsible!',
        description: 'We are space pirates - yearning to explore new horizonts, breaking some rules, enjoing the freedom of adventure. And we never forget that «with great power, comes great responsibility».',
        icon: icon1,
         
    },
    {
        number: '02.',
        name: 'We work together - help each other',
        description: 'As a pirate crew we are as «tight» as it gets - true happy pirate family - helping each other with work and beyond. We inspire each other to become the best versions of ourselves.',
        icon: icon2,
        background: true
    },
    {
        number: '03.',
        name: 'Growth mindset',
        description: 'This is fundamental - this is our DNA. Constant growth and development are at the core of our people. We have HUGE goals and lots of questions and we believe that only by learning and constant movement can we achieve our grandest dreams.',
        icon: icon3,
         
    },
    {
        number: '04.',
        name: 'Make mistakes, learn from them, move forward',
        description: 'Who said «practice makes perfect»? We believe that by practicing you can make mistakes, learn from those and become stronger by it. Mistakes are part of the process. If you are not making mistakes it only means you are not growing, nor acting.',
        icon: icon4,
         
    },
    {
        number: '05.',
        name: 'Open and truthful',
        description: 'As a pirate crew we believe in radical candor - no need to sugar-coat issues - we are on the same ship and there is no room for «an elephant in the room».',
        icon: icon5,
         
    },
    {
        number: '06.',
        name: 'Flexible approach',
        description: 'As pirates, our tactics to achieve goals must always remain flexible. You close one door, we’ll come through the window; block the window, we’ll slide down the chimney - we’ll always find the way, it’s in our nature.',
        icon: icon6,
         
    },
    {
        number: '07.',
        name: 'Move into scary things',
        description: 'Doing scary things gives the best energy boost - mentally and physically. We have scary HUGE goals, but also scary AMAZING people working on those.',
        icon: icon7,
         
    },
    {
        number: '08.',
        name: 'Best people at every position',
        description: 'The best reward anyone can get working on difficult tasks - is to have someone amazing tackling the same issue by your side. Pirate life is hard, especially in cosmos, so having an amazing crew is vital to us.',
        icon: icon8
    },
    {
        number: '09.',
        name: 'People over process',
        description: 'While processes are important for company growth, as pirates we believe in freedom and we believe in our people to make responsible decisions even if processes need to be sacrificed.',
        icon: icon9,
         
    },
    {
        number: '10.',
        name: 'Doers over theorists',
        description: 'Instead of planning and overplanning we just do. Do, evaluate, repeat.',
        icon: icon10,
         
    },
    {
        number: '11.',
        name: 'Nothing is impossible! Find the way!',
        description: 'As simple as that!',
        icon: icon11,
         
    },
    {
        number: '12.',
        name: 'Sharing the spoils',
        description: 'Pirate life is not easy, but can be hugely rewarding. We believe in fair «treasure distribution». Everyone gets to share the loot on the merit of personal contribution. Oh, and we celebrate our achievements in true pirate fashion:)',
        icon: icon12,
         
    },
    {
        number: '13.',
        name: 'No jerks!',
        description: 'This is simple: we don’t tolerate jerks, we don’t tolerate «politics».',
        icon: icon13,
         
    },


]

export { rules }