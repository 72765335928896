import React from 'react'
import './styleManifesto.css'
import { rules } from './Rules'
import { Container } from 'react-bootstrap'
import background1 from './images/background1.svg'
import background2 from './images/background2.svg'
import { Helmet } from "react-helmet";

export default function Manifesto() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Banx21 - Our Manifesto</title>
                <meta name="description" content="We see a world where people can move money freely, store it securely and keep its nominal value - an apolitical financial approach for improving people's lives." />
            </Helmet>
            <img src={background2} alt='background' className='background2' />
            <section id='manifesto'>
                <Container className='overflow-inferit'>
                    <div className='heading position-relative'>
                        <h1>Manifesto</h1>
                        <img src={background1} alt='background' className='background1' />
                    </div>
                    <div className='manifesto-description'>
                        <p className='body1'>
                            From the outset, given the audacity of our goals, we placed PEOPLE at the centre of our strategy - this manifesto was the very first formal document that we created.
                        </p>
                    </div>
                    <div className='rules'>
                        {rules.map((rule, index) => {
                            return (
                                <div className={`rule`} key={index}>
                                    <div className='rule-icon'>
                                        <img src={rule.icon} />
                                    </div>
                                    <div className='rule-description'>
                                        <div className='rule-heading'>
                                            <p className='rule-name'> <span className='rule-number'>{rule.number}</span> {rule.name} </p>
                                        </div>
                                        <div className='rule-text'>
                                            <p className='body6'>
                                                {rule.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Container>
            </section>
        </>
    )
}
