import './App.css';
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer';
import HomePage from './components/Home/HomePage';
import Manifesto from './components/Manifesto/Manifesto';
import Events from './components/CompanyEvents/Events';
import EventCard from './components/CompanyEvents/EventCard';
import 'bootstrap/dist/css/bootstrap.css';
import ScrollToTop from "./ScrollToTop";
import {
  Routes,
  Route,
} from "react-router-dom";
import React, { useState } from 'react';


function App() {
  const [link, setLink] = useState('')
  const setNewLink = (newLink) => {
    setLink(newLink)
  }
  return (
    <>
      <Header activeLink={link} />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/manifesto" element={<Manifesto />} />
          <Route path="/events" element={<Events />} />
          <Route path="/event/:id" element={<EventCard />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </ScrollToTop>
      <Footer setNewLink={setNewLink} />
    </>
  );
}

export default App;
