import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { events } from './List'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { ArrowRight, ArrowLeft } from './images/arrows'
import { motion, AnimatePresence } from "framer-motion"
import cross from './images/cross.svg'
import './viewmode.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { EffectCards } from "swiper";
import "swiper/css/effect-cards";
import background1 from './images/background1.svg'



function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}


function getEvent(id) {
    return events.find(({ link }) => link === id)
}

function getRandomInt(min = -15, max = 15) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export default function Event() {
    const { id } = useParams()
    const [event, setEvent] = useState()
    const [pictures, changePictures] = useState()
    const [viewmode, setViewmode] = useState({ status: false, image: null })
    const [viewFormat, setViewFormat] = useState(false)
    const [linearViewFormat, setLinearViewFormat] = useState({ status: false, image: null })
    const { width } = useWindowDimensions();
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);

    useEffect(() => {
        const newEvent = getEvent(id)
        setEvent(newEvent)
        const newPictures = newEvent.images
        newPictures.forEach((picture, index) => {
            picture.rotation = getRandomInt()
            picture.zIndex = index
            picture.isOpen = true
            picture.viewMode = false
        })
        changePictures(newPictures)
    }, [id])

    useEffect(() => {
        const newEvent = getEvent(id)
        setEvent(newEvent)
        const newPictures = newEvent.images
        newPictures.forEach((picture, index) => {
            picture.rotation = getRandomInt()
            picture.zIndex = index
            picture.isOpen = true
            picture.viewMode = false
        })
        changePictures(newPictures)
    }, [])

    const imageChange = {
        open: {
            translateX: '-50%',
            translateY: '-50%',
            width: '60%',
            transition: {
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        },
        closed: {
            x: width < 992 ? -300 : width < 1200 ? -400 : width < 1400 ? -470 : -600,
            y: width < 992 ? -450 : width < 1200 ? -420 : -420,
            width: '20%',
            rotate: 0,
            transition: {
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        },
        animate: {
            scale: 1.4,
            rotate: 0
        }
    };

    const findOpenPictures = (pictures) => {
        return pictures.filter(picture => picture.isOpen)
    }

    const findClosedPictures = (pictures) => {
        return pictures.filter(picture => !picture.isOpen)
    }

    const findBiggestZindex = (pictures) => {
        let biggestZindex = 0
        pictures.forEach((picture) => {
            if (picture.zIndex > biggestZindex) {
                biggestZindex = picture.zIndex
            }
        })
        return biggestZindex
    }

    const next = () => {
        if (pictures.filter(picture => !picture.isOpen).length !== pictures.length) {
            let openPictures = findOpenPictures(pictures)
            let closedPictures = findClosedPictures(pictures)
            if (pictures.length > 1 && pictures.filter(picture => picture.isOpen).length > 1) {
                let biggestZindex = findBiggestZindex(openPictures)
                let biggestClosedZindex = findBiggestZindex(closedPictures)
                let imageToClose = openPictures.find(picture => picture.zIndex === biggestZindex)
                imageToClose.isOpen = false
                imageToClose.zIndex = biggestClosedZindex + 1
                if (viewmode.status) {
                    imageToClose.viewMode = false
                    openPictures[openPictures.length - 2].viewMode = true
                    openPictures[openPictures.length - 2].zIndex = pictures.length + 2
                    viewmode.image = openPictures[openPictures.length - 2]
                    setViewmode({ ...viewmode })
                }
                changePictures(pictures => ([
                    ...pictures
                ]))
            } else{
                openPictures[0].isOpen = false
                openPictures.zIndex = 1
                closedPictures.forEach((picture, index)=>{
                    picture.isOpen = true
                    picture.zIndex = index
                })
                if (viewmode.status) {
                    openPictures[0].viewMode = false
                    openPictures[0].zIndex = 0
                    closedPictures[closedPictures.length-1].viewMode = true
                    closedPictures[closedPictures.length-1].zIndex = pictures.length + 2
                    viewmode.image = closedPictures[closedPictures.length-1]
                    setViewmode({ ...viewmode })
                }        
                changePictures(pictures => ([
                    ...pictures
                ]))      
            }
        }
    }
    const prev = () => {
        const closedPictures = findClosedPictures(pictures)
        const openPictures = findOpenPictures(pictures)
        if (closedPictures.length !== 0) {
            const biggestClosedZindex = findBiggestZindex(closedPictures)
            const biggestOpenZindex = findBiggestZindex(openPictures)
            const imageToOpen = closedPictures.find(picture => picture.zIndex === biggestClosedZindex)
            imageToOpen.isOpen = true
            imageToOpen.zIndex = biggestOpenZindex + 1
            if (viewmode.status) {
                imageToOpen.viewMode = true
                openPictures[openPictures.length - 1].viewMode = false
                openPictures[openPictures.length - 1].zIndex = openPictures.indexOf(openPictures[openPictures.length - 1])
                viewmode.image = imageToOpen
                setViewmode({ ...viewmode })
            }
            changePictures(pictures => ([
                ...pictures
            ]))
        } else {
            openPictures.slice().reverse().forEach((picture, idx)=>{
                if (idx !== openPictures.length-1){ 
                    picture.isOpen = false
                    picture.zIndex = idx
                }
            })        
            if (viewmode.status) {
                openPictures[openPictures.length-1].viewMode = false
                openPictures[openPictures.length-1].isOpen = false
                openPictures[openPictures.length-1].zIndex = 0 
                openPictures[0].viewMode = true
                openPictures[0].isOpen = true
                openPictures[0].zIndex = pictures.length + 2
                viewmode.image = openPictures[0]
                setViewmode({ ...viewmode })
            }
            changePictures(pictures => ([
                ...pictures
            ]))      
        }
    }

    const openViewMode = (image) => {
        const openPictures = findOpenPictures(pictures)
        const biggestZindex = findBiggestZindex(openPictures)
        const lastOpenPicture = openPictures.find(picture => picture.zIndex === biggestZindex)
        if (image.id == lastOpenPicture.id && !image.viewMode) {
            viewmode.status = true
            viewmode.image = image
            setViewmode({ ...viewmode, status: true })
            image.zIndex = pictures.length + 2
            image.viewMode = true
        }
    }

    const closeViewMode = () => {
        const openPictures = findOpenPictures(pictures)
        const image = viewmode.image
        image.zIndex = openPictures.indexOf(openPictures[openPictures.length - 1])
        image.viewMode = false
        viewmode.status = false
        setViewmode({ ...viewmode, status: false, image: null })
    }

    const linearRight = () => {
        let index = event.images.indexOf(linearViewFormat.image)
        index--
        if (index < 0) index = event.images.length - 1
        const newlinearViewFormat = {
            status: true,
            image: event.images[index]
        }
        setLinearViewFormat(newlinearViewFormat)
    }

    const linearLeft = () => {
        let index = event.images.indexOf(linearViewFormat.image)
        index--
        if (index < 0) index = event.images.length - 1
        const newlinearViewFormat = {
            status: true,
            image: event.images[index]
        }
        setLinearViewFormat(newlinearViewFormat)
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (linearViewFormat.status && event.key === 'Escape') {
                event.preventDefault();
                const newlinearViewFormat = {
                    status: false,
                    image: null
                }
                setLinearViewFormat(newlinearViewFormat)
            }
            if (linearViewFormat.status && event.keyCode === 39) {
                linearRight()
            }
            if (linearViewFormat.status && event.keyCode === 37) {
                linearLeft()
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [linearViewFormat]);

    return (
        <section id='event'>
            {pictures === undefined ? (
                <h1>Loading</h1>
            ) : (
                <>
                    {viewmode.status ? (<div className='viewmode-overlay' style={{ zIndex: pictures.length + 1 }} onClick={() => closeViewMode()} />) : (null)}
                    {linearViewFormat.status && width > 770 ? (
                        <div className='linear-viewmode d-flex align-items-center justify-content-center'>
                            <div className='close-overlay'
                                onClick={() => {
                                    const newlinearViewFormat = {
                                        status: false,
                                        image: null
                                    }
                                    setLinearViewFormat(newlinearViewFormat)
                                }}
                            />
                            <div className='carousel-btn'>
                                <button
                                    onClick={() => {
                                        let index = event.images.indexOf(linearViewFormat.image)
                                        index--
                                        if (index < 0) index = event.images.length - 1
                                        const newlinearViewFormat = {
                                            status: true,
                                            image: event.images[index]
                                        }
                                        setLinearViewFormat(newlinearViewFormat)
                                    }}
                                >
                                    <ArrowLeft />
                                </button>
                            </div>
                            <div className='linear-image'>
                                <div className='position-relative image-group'>
                                    <img src={linearViewFormat.image.path} alt='event-image' className='img-fluid' />
                                </div>
                            </div>
                            <div className='carousel-btn'>
                                <button
                                    onClick={() => {
                                        linearRight()
                                    }}
                                >
                                    <ArrowRight />
                                </button>
                            </div>
                        </div>
                    ) : (null)}
                    <Container>
                        <div className='link-back'>
                            <Link to='/events' className='d-flex align-items-center link-back'>
                                <svg width="21" height="8" viewBox="0 0 21 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM1 4.5H21V3.5H1V4.5Z" fill="currentColor" />
                                </svg>
                                <span className='ms-2'>Company events</span>
                            </Link>
                        </div>
                        <div className='event-name position-relative'>
                            <h1>
                                {event.name}
                            </h1>
                            <img src={background1} alt='background' className='background1' />
                        </div>
                        <div className='view-format'>
                            <div onClick={() => { setViewFormat(!viewFormat) }}>
                                {!viewFormat ? (
                                    <svg className='format1' viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="6.52228" height="6.71675" fill="currentColor" />
                                        <rect x="11.957" width="6.52228" height="6.71675" fill="currentColor" />
                                        <rect x="23.4785" width="6.52228" height="6.71675" fill="currentColor" />
                                        <rect x="23.4785" y="11.6431" width="6.52228" height="6.71675" fill="currentColor" />
                                        <rect x="11.957" y="11.6431" width="6.52228" height="6.71675" fill="currentColor" />
                                        <rect y="11.6431" width="6.52228" height="6.71675" fill="currentColor" />
                                        <rect y="23.2842" width="6.52228" height="6.71675" fill="currentColor" />
                                        <rect x="11.957" y="23.2842" width="6.52228" height="6.71675" fill="currentColor" />
                                        <rect x="23.4785" y="23.2842" width="6.52228" height="6.71675" fill="currentColor" />
                                    </svg>
                                ) : (
                                    <svg className='format2' width="156" height="62" viewBox="0 0 156 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="55" width="46" height="62" fill="currentColor" />
                                        <path d="M155.354 31.3536C155.549 31.1583 155.549 30.8417 155.354 30.6464L152.172 27.4645C151.976 27.2692 151.66 27.2692 151.464 27.4645C151.269 27.6597 151.269 27.9763 151.464 28.1716L154.293 31L151.464 33.8284C151.269 34.0237 151.269 34.3403 151.464 34.5355C151.66 34.7308 151.976 34.7308 152.172 34.5355L155.354 31.3536ZM139 31.5H155V30.5H139V31.5Z" fill="currentColor" />
                                        <path d="M0.646446 31.3536C0.451185 31.1583 0.451185 30.8417 0.646446 30.6464L3.82843 27.4645C4.02369 27.2692 4.34027 27.2692 4.53553 27.4645C4.7308 27.6597 4.7308 27.9763 4.53553 28.1716L1.70711 31L4.53553 33.8284C4.7308 34.0237 4.7308 34.3403 4.53553 34.5355C4.34027 34.7308 4.02369 34.7308 3.82843 34.5355L0.646446 31.3536ZM17 31.5H1V30.5H17V31.5Z" fill="currentColor" />
                                        <rect x="25" y="16" width="22" height="30" fill="currentColor" />
                                        <rect x="109" y="16" width="22" height="30" fill="currentColor" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        <div>
                            {!viewFormat ? (
                                <>
                                    {width < 770 ? (
                                        <>
                                            <Swiper
                                                effect={"cards"}
                                                grabCursor={true}
                                                modules={[EffectCards]}
                                                className="mySwiper overflow-hidden"
                                            >
                                                {pictures.map((picture, index) => {
                                                    return (
                                                        <SwiperSlide key={index}>
                                                            <img src={picture.path} alt='picture' className='img-fluid' loading="lazy" />
                                                        </SwiperSlide>
                                                    )
                                                })}
                                            </Swiper>
                                        </>
                                    ) : (
                                        <>
                                            <div className='d-flex justify-content-center image-container'>
                                                <div className='carousel-btn'>
                                                    <button
                                                        style={{ zIndex: viewmode ? (pictures.length + 1) : null }}
                                                        onClick={() => prev()}
                                                    >
                                                        <ArrowLeft />
                                                    </button>
                                                </div>
                                                <div className='image-stack-container position-relative'>
                                                    <AnimatePresence>
                                                        {pictures.map((image, index) => {
                                                            return (
                                                                <motion.div className='event-image' key={index} style={{
                                                                    rotate: index === 0 ? ('0deg') : (`${image.rotation}deg`),
                                                                    zIndex: image.zIndex,
                                                                }}
                                                                    initial={{ translateX: '-50%', translateY: '-50%' }}
                                                                    animate={image.viewMode ? "animate" : image.isOpen ? "open" : !image.hasOwnProperty('isOpen') ? "open" : "closed"}
                                                                    variants={imageChange}
                                                                >
                                                                    {viewmode.status && image.id === viewmode.image.id ? (
                                                                        <div className='close-btn' onClick={() => closeViewMode()}>
                                                                            <img src={cross} alt='cross icon' />
                                                                        </div>
                                                                    ) : null}
                                                                    <img src={image.path} alt='event image' className='event-image-carousel'
                                                                        onClick={() => {
                                                                            openViewMode(image)
                                                                        }}
                                                                        onMouseEnter={(e) => {
                                                                            if (viewmode.status && viewmode.image.id === image.id) {
                                                                                const elem = e.currentTarget;
                                                                                const { width, height } = elem.getBoundingClientRect();
                                                                                setSize([width, height]);
                                                                                setShowMagnifier(true);
                                                                            }
                                                                        }}
                                                                        onMouseMove={(e) => {
                                                                            if (viewmode.status && viewmode.image.id === image.id) {
                                                                                const elem = e.currentTarget;
                                                                                const { top, left } = elem.getBoundingClientRect();
                                                                                const x = e.pageX - left - window.pageXOffset;
                                                                                const y = e.pageY - top - window.pageYOffset;
                                                                                setXY([x, y]);
                                                                            }
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setShowMagnifier(false);
                                                                        }}
                                                                        style={{
                                                                            cursor: viewmode.status ? 'default' : 'pointer'
                                                                        }}
                                                                    />
                                                                    {viewmode.status && image.id === viewmode.image.id ? (
                                                                        <div id='magnifier'
                                                                            style={{
                                                                                display: showMagnifier ? "" : "none",
                                                                                position: "absolute",
                                                                                pointerEvents: "none",
                                                                                height: `100px`,
                                                                                width: `100px`,
                                                                                top: `${(y - 100 / 2) / 1.4}px`,
                                                                                left: `${(x - 100 / 2) / 1.4}px`,
                                                                                opacity: "1",
                                                                                border: "1px solid lightgray",
                                                                                backgroundColor: "var(--light-black)",
                                                                                backgroundImage: `url('${image.path}')`,
                                                                                backgroundRepeat: "no-repeat",
                                                                                backgroundSize: `${imgWidth * 1.4}px ${imgHeight * 1.4
                                                                                    }px`,
                                                                                backgroundPositionX: `${-x * 1.4 + 100 / 2}px`,
                                                                                backgroundPositionY: `${-y * 1.4 + 100 / 2}px`,
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                </motion.div>
                                                            )
                                                        })}
                                                    </AnimatePresence>
                                                </div>
                                                <div className='carousel-btn'>
                                                    <button
                                                        onClick={() => next()}
                                                        style={{ zIndex: viewmode ? (pictures.length + 1) : null }}
                                                    >
                                                        <ArrowRight />
                                                    </button>
                                                </div>
                                            </div >
                                            <div className='image-counter'>
                                                <span> {!pictures[0].hasOwnProperty('isOpen') ? (<div>1</div>) : (pictures.filter(picture => !picture.isOpen).length + 1)} </span> / <span>{pictures.length}</span>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Row className='gy-5 picture-gallery'>
                                    {pictures.map((picture, index) => {
                                        return (
                                            <Col className='d-flex align-items-center justify-content-center gallery-image' key={index} xs={12} md={6} lg={4}>
                                                <img src={picture.path} alt={picture.id}
                                                    onClick={() => {
                                                        const newlinearViewFormat = {
                                                            status: true,
                                                            image: picture
                                                        }
                                                        setLinearViewFormat(newlinearViewFormat)
                                                    }}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            )}
                        </div>
                    </Container >
                </>
            )}
        </section >
    )
}
