import React from 'react'
import Event from './Event'
import RandomEvents from './RandomEvents'

export default function EventCard() {
    return (
        <>
            <Event />
            <RandomEvents/>
        </>
    )
}
