const cryptoImages = [
    {
        id: 'crypto-img-1',
        path: require('./events/crypto/crypto-1-compressed.jpg'),
    },
    {
        id: 'crypto-img-2',
        path: require('./events/crypto/crypto-2-compressed.jpg'),
    },
    {
        id: 'crypto-img-3',
        path: require('./events/crypto/crypto-3-compressed.jpg'),
    },
    {
        id: 'crypto-img-4',
        path: require('./events/crypto/crypto-4-compressed.jpg'),
    },
    {
        id: 'crypto-img-5',
        path: require('./events/crypto/crypto-5-compressed.jpg'),
    },
    {
        id: 'crypto-img-6',
        path: require('./events/crypto/crypto-6-compressed.jpg'),
    },
    {
        id: 'crypto-img-7',
        path: require('./events/crypto/crypto-7-compressed.jpg'),
    },
    {
        id: 'crypto-img-8',
        path: require('./events/crypto/crypto-8-compressed.jpg'),
    },
    {
        id: 'crypto-img-9',
        path: require('./events/crypto/crypto-9-compressed.jpg'),
    },
    {
        id: 'crypto-img-10',
        path: require('./events/crypto/crypto-10-compressed.jpg'),
    },
]

const dubaiImages = [
    {
        id: 'dubai-img-1',
        path: require('./events/dubai/dubai-1-compressed.jpg'),
    },
    {
        id: 'dubai-img-2',
        path: require('./events/dubai/dubai-2-compressed.jpg'),
    },
    {
        id: 'dubai-img-3',
        path: require('./events/dubai/dubai-3-compressed.jpg'),
    },
    {
        id: 'dubai-img-4',
        path: require('./events/dubai/dubai-4-compressed.jpg'),
    },
    {
        id: 'dubai-img-5',
        path: require('./events/dubai/dubai-5-compressed.jpg'),
    },
    {
        id: 'dubai-img-6',
        path: require('./events/dubai/dubai-6-compressed.jpg'),
    },
    {
        id: 'dubai-img-7',
        path: require('./events/dubai/dubai-7-compressed.jpg'),
    },
    {
        id: 'dubai-img-8',
        path: require('./events/dubai/dubai-8-compressed.jpg'),
    },
    {
        id: 'dubai-img-9',
        path: require('./events/dubai/dubai-9-compressed.jpg'),
    },
    {
        id: 'dubai-img-10',
        path: require('./events/dubai/dubai-10-compressed.jpg'),
    },
    {
        id: 'dubai-img-11',
        path: require('./events/dubai/dubai-11-compressed.jpg'),
    },
    {
        id: 'dubai-img-12',
        path: require('./events/dubai/dubai-12-compressed.jpg'),
    },
    {
        id: 'dubai-img-13',
        path: require('./events/dubai/dubai-13-compressed.jpg'),
    },
    {
        id: 'dubai-img-14',
        path: require('./events/dubai/dubai-14-compressed.jpg'),
    },
    {
        id: 'dubai-img-15',
        path: require('./events/dubai/dubai-15-compressed.jpg'),
    },
    {
        id: 'dubai-img-16',
        path: require('./events/dubai/dubai-16-compressed.jpg'),
    },
    {
        id: 'dubai-img-17',
        path: require('./events/dubai/dubai-17-compressed.jpg'),
    },
    {
        id: 'dubai-img-18',
        path: require('./events/dubai/dubai-18-compressed.jpg'),
    },
    {
        id: 'dubai-img-19',
        path: require('./events/dubai/dubai-19-compressed.jpg'),
    },
    {
        id: 'dubai-img-20',
        path: require('./events/dubai/dubai-20-compressed.jpg'),
    },
    {
        id: 'dubai-img-21',
        path: require('./events/dubai/dubai-21-compressed.jpg'),
    },
    {
        id: 'dubai-img-22',
        path: require('./events/dubai/dubai-22-compressed.jpg'),
    },
]

const ethccParisImages = [
    {
        id: 'ethcc-paris-2022-img-1',
        path: require('./events/ethCC-paris/ethCC-paris-2022-1.jpg'),
    },
    {
        id: 'ethcc-paris-2022-img-2',
        path: require('./events/ethCC-paris/ethCC-paris-2022-2.jpg'),
    },
    {
        id: 'ethcc-paris-2022-img-3',
        path: require('./events/ethCC-paris/ethCC-paris-2022-3.jpg'),
    },
    {
        id: 'ethcc-paris-2022-img-4',
        path: require('./events/ethCC-paris/ethCC-paris-2022-4.jpg'),
    },
    {
        id: 'ethcc-paris-2022-img-5',
        path: require('./events/ethCC-paris/ethCC-paris-2022-5.jpg'),
    }
]

const ethccBarcelonaImages = [
    {
        id: 'ethcc-barcelona-2022-img-1',
        path: require('./events/ethCC-barcelona/ethCC-barcelona-2022-1.jpg'),
    },
    {
        id: 'ethcc-barcelona-2022-img-2',
        path: require('./events/ethCC-barcelona/ethCC-barcelona-2022-2.jpg'),
    },
    {
        id: 'ethcc-barcelona-2022-img-3',
        path: require('./events/ethCC-barcelona/ethCC-barcelona-2022-3.jpg'),
    },
    {
        id: 'ethcc-barcelona-2022-img-4',
        path: require('./events/ethCC-barcelona/ethCC-barcelona-2022-4.jpg'),
    },
    {
        id: 'ethcc-barcelona-2022-img-5',
        path: require('./events/ethCC-barcelona/ethCC-barcelona-2022-5.jpg'),
    }
]

export const events = [
    {
        id: 1,
        link: 'crypto-fintech-friends-meet-up',
        name: '/Crypto /Fintech /Friends Meet Up',
        images: cryptoImages
    },
    {
        id: 2,
        link: 'dubai-2022',
        name: 'Dubai 2022',
        images: dubaiImages
    },
    {
        id: 3,
        link: 'ethCC-paris-2022',
        name: 'EthCC in Paris 2022',
        images: ethccParisImages
    },
    {
        id: 4,
        link: 'ethbarcelona-2022',
        name: 'EthBarcelona 2022',
        images: ethccBarcelonaImages
    },
]
