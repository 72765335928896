import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../images/logo.svg';
import './style.css'
import { Link } from 'react-router-dom';

export default function Footer({ setNewLink }) {
    return (
        <footer className='section-margin-260'>
            <Container>
                <Row className='footer'>
                    <Col xs={8} sm={4} md={4} lg className='d-flex align-items-sm-center align-items-xs-start'>
                        <Link to='/'>
                            <img src={logo} alt='banx21' className='logo-footer' />
                        </Link>
                    </Col>
                    <Col xs={4} sm={8} md={4} lg={6} className='footer-links d-flex align-items-center justify-content-xs-start justify-content-sm-end justify-content-md-center flex-column flex-sm-row flex-md-column flex-lg-row mt-md-0'>
                        <ul className="footer-list">
                            <li className="nav-item">
                                <Link to='manifesto' className='me-lg-4' onClick={() => setNewLink('manifesto')}>manifesto</Link>
                            </li>
                            <li className="nav-item">
                                <Link to='events' className='me-lg-4'>company events</Link>
                            </li>
                            <li className="nav-item">
                                <a href="https://libernetix.freshteam.com/jobs" target="_blank">careers</a>
                            </li>
                        </ul>
                    </Col>
                    <Col sm={12} md={4} lg className='d-flex align-items-center justify-content-md-end mt-4 mt-md-0 rights'>
                        <Row className='body10'>
                            <Col>©  {new Date().getFullYear()} banx21. All rights reserved</Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
