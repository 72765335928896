export const projects = [
    {
        id: 'metamouse',
        description: 'Payment infrastracture for Web3',
        link: 'https://metamouse.cc/',
        linkText: 'metamouse.cc'
    },
    {
        id: 'libernetix',
        description: 'Online merchants and  acquiring solutions',
        link: 'https://libernetix.com/en',
        linkText: 'libernetix.com'
    },
    {
        id: 'orbios.money',
        description: 'Crypto-finance platform and exchange',
        link: 'http://www.orbios.money/',
        linkText: 'orbios.money'
    },
]