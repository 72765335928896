import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './style.css'
import altercaplogo from './logos/altercaplogo.png';
import { motion } from "framer-motion"
import { Metamouselogo } from './logos/Metamouselogo';
import { Arrow } from './logos/Arrow';
import { projects } from './projects';
import LibernetixLogo from './logos/libernetix_logo.svg';

export default function ProjectsSection() {
    const renderSwitch = (param) => {
        switch (param) {
            case 'metamouse':
                return <Metamouselogo />;
            case 'libernetix':
                return <img src={LibernetixLogo} alt='libernetix' className='company-logo' />
            case 'orbios.money':
                return <div className='blur-logo' />
            default:
                return 'error';
        }
    }
    return (
        <section className='section-margin-big'>
            <Container id='projects'>
                <div>
                    <Col className='d-flex justify-content-center'>
                        <h2 className='text-center project-heading'>Projects</h2>
                    </Col>
                </div>
                <div className='d-flex justify-content-center section-text-margin-65'>
                    <p className='projects-align-text projects-text'>
                        We are currently building several businesses both in traditional fiat space and in crypto - these will later merge to become Banx21 - a radically new way to share and store value for ordinary people and businesses around the world.
                    </p>
                </div>
                <div className='section-projects align-items-end project-grid'>
                    {projects.map((project,index) => {
                        return (
                            <motion.div key={project.id} className='project-card'
                                initial={{ y: index === 1 ? 100:-100, opacity: 0 }}
                                whileInView={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.5, ease: 'easeIn' }}
                                viewport={{ once: true }}
                            >
                                <div className='project-card-name'>
                                    <a href={project.link} className='project-link' target='__blank'>
                                        <div className='project-card-logo'>
                                            {renderSwitch(project.id)}
                                        </div>
                                        <div className='project-card-arrow'>
                                            <Arrow />
                                        </div>
                                    </a>
                                </div>
                                <div className='project-card-info'>
                                    <div className='project-card-description'>
                                        <span className='body8'>{project.description}</span>
                                    </div>
                                    <div className='project-card-link'>
                                        <a href={project.link} className='body9' target='__blank'>{project.linkText}</a>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    })}
                </div>
            </Container>
        </section>
    )
}
