import React from 'react'
import InfoSection from '../InfoSection/InfoSection';
import PSSection from '../PSSection/PSSection';
import ProjectsSection from '../Projects/ProjectsSection';
import Leads from '../Leads/Leads';
import MainSection from '../MainSection/MainSection';
import { Helmet } from "react-helmet";

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Banx21 - We Create a New Financial World Not Only in Words</title>
        <meta name="description" content="We see a world where people can move money freely, store it securely and keep its nominal value - an apolitical financial system that works to improve people's lives." />
      </Helmet>
      <MainSection />
      <InfoSection />
      <PSSection />
      <ProjectsSection />
      <Leads />
    </>
  )
}
