import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { events } from './List'
import { useParams } from 'react-router-dom'

function getRandomInt(min = 0, max = events.length) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

function getRandomEvents(id) {
    if (events.length > 1) {
        let randomEventsIndex = []
        let randomEvents = []
        const currentEvent = events.find(event => event.link === id)
        const currentEventIndex = events.indexOf(currentEvent)
        for (let index = 0; index < 3; index++) {
            let newIndex = getRandomInt()
            while (randomEventsIndex.includes(newIndex) || newIndex === currentEventIndex) {
                newIndex = getRandomInt()
            }
            randomEventsIndex.push(newIndex)
        }
        for (let index = 0; index < 3; index++) {
            randomEvents.push(events[randomEventsIndex[index]])
        }
        return randomEvents
    }
}

export default function RandomEvents() {
    const { id } = useParams()
    const randomEvents = getRandomEvents(id)
    const [eventList, changeEvents] = useState(randomEvents)
    const handleMouseEnter = (hoverEvent) => {
        const newEvents = [...eventList]
        newEvents.forEach((event) => {
            if (event.id === hoverEvent.id) {
                event.isHover = true
            }
        })
        changeEvents(newEvents)
    };
    const handleMouseLeave = (event) => {
        event.isHover = false
        changeEvents(eventList => ([
            ...eventList
        ]))
    };
    return (
        <Container>
            <div className='random-events'>
                {events.length === 1 ? (
                    <div className='random-events-heading'>
                        More events are coming
                    </div>
                ) : (
                    <>
                        <div className='random-events-heading'>
                            See the other photo
                        </div>
                        <div className='random-events-links'>
                            {eventList.map((event, index) => {
                                return (
                                    <Link to={`/event/${event.link}`} key={index} className='grid-image random'
                                        style={{
                                            background: event.isHover ? (`linear-gradient(90deg, rgba(0,0,0,0.5032606792717087) 0%, rgba(0,0,0,0.5) 100%), url(${event.images[0].path})`) : (`linear-gradient(180deg, rgba(217, 217, 217, 0) 50%, #FFFFFF 110%), url(${event.images[0].path})`)
                                        }}
                                        onMouseEnter={() => handleMouseEnter(event)}
                                        onMouseLeave={() => handleMouseLeave(event)}>
                                        <div className='image-heading'>
                                            {event.name}
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </>
                )}
            </div>
        </Container>
    )
}
