import React, { useEffect } from 'react'
import { events } from './List'
import { Link } from 'react-router-dom'
import { useState } from 'react';

export default function Eventgrid() {
    const [eventList, changeEvents] = useState(null)
    const handleMouseEnter = (hoverEvent) => {
        const newEvents = [...eventList]
        newEvents.forEach((event) => {
            if (event.id === hoverEvent.id) {
                event.isHover = true
            }
        })
        changeEvents(newEvents)
    };
    const handleMouseLeave = (event) => {
        event.isHover = false
        changeEvents(eventList => ([
            ...eventList
        ]))
    };
    useEffect(() => {
        events.forEach(event => event.isHover = false)
        changeEvents(events)
    }, [])


    return (
        <>
            <div className='choose-events-year'>
                <div className='body9 active'>2022</div>
            </div>
            {eventList === null ? (
                <div className='d-flex justify-content-center'>
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            ) : (
                <div className='events-pictures d-flex justify-content-between'>
                    {/* <div className='grid'>
                        {eventList.map((event) => {
                            return (
                                <Link to={`/event/${event.link}`} key={event.id} className={`grid-image image${event.id}`}
                                    style={{
                                        background: event.isHover ? (`linear-gradient(90deg, rgba(0,0,0,0.5032606792717087) 0%, rgba(0,0,0,0.5) 100%), url(${event.images[0].path})`) : (`linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 110%), url(${event.images[0].path})`)
                                    }}
                                    onMouseEnter={() => handleMouseEnter(event)}
                                    onMouseLeave={() => handleMouseLeave(event)}>
                                    <div className='image-heading'>{event.name}</div>
                                </Link>
                            )
                        })}
                    </div> */}
                    {eventList.map((event) => {
                        return (
                            <Link to={`/event/${event.link}`} key={event.id} className={`grid-image`}
                                style={{
                                    background: event.isHover ? (`linear-gradient(90deg, rgba(0,0,0,0.5032606792717087) 0%, rgba(0,0,0,0.5) 100%), url(${event.images[0].path})`) : (`linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 110%), url(${event.images[0].path})`),
                                    height: '300px',
                                    width: '300px'
                                }}
                                onMouseEnter={() => handleMouseEnter(event)}
                                onMouseLeave={() => handleMouseLeave(event)}>
                                <div className='image-heading'>{event.name}</div>
                            </Link>
                        )
                    })}
                </div>
            )
            }
        </>
    )
}
