import React from 'react'
import './style.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { motion } from "framer-motion"
import background from './background.svg'
import arrow1 from './arrows/Arrows-1.svg'
import arrow2 from './arrows/Arrows-2.svg'
import arrow3 from './arrows/Arrows-3.svg'
import { useState, useEffect } from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}


export default function MainSection() {
    const { width } = useWindowDimensions();
    return (
        <>
            <motion.div id='picture-background' style={{ backgroundImage: `url(${background})` }}
                initial={{ backgroundSize: '120%', opacity: 0 }}
                whileInView={{ backgroundSize: '100%', opacity: 1 }}
                transition={{ duration: 0.7, delay: 3 }}
                viewport={{ once: true }}
            />
            <Row id='main' className='justify-content-center m-0'>
                <div id='welcome' className='d-flex justify-content-center align-items-center position-relative'>
                    <motion.div
                        initial={{ scale: 2, rotate: 90, 
                            translateX: 
                            width <= 300 ? 60 :
                            width <= 480 ? 70 : 
                            width <= 768 ? 90 : 
                            width <= 1440 ? 160 : 
                            width <= 1920 ? 170 :
                            170
                        }}
                        whileInView={{ scale: 1, rotate: 0, translateX: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        viewport={{ once: true }}
                        id='border-gradient' />
                    <h4 className='m-0'>
                        <motion.div
                            initial={{ opacity: 0, y: -100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1, delay: 2, ease: 'easeInOut' }}
                        >
                            For people. <br />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: -100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1, delay: 1.5, ease: 'easeInOut' }}>
                            For better lives. <br />
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: -100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 1, delay: 1, ease: 'easeInOut' }}>
                            For progress. <br />
                        </motion.div>
                    </h4>
                </div>
            </Row>
            <Container className='new-section'>
                <Row className='welcome-text justify-content-center'>
                    <div className="body2" id='main-intro'>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 2, ease: 'easeInOut' }}
                        >
                            We believe in a world where people can
                            <span className='gradient-text'> move capital </span>
                            freely, store it securely and
                            retain its nominal value - an a-
                            <span className='gradient-text'>political financial system </span> designed to serve people in betterment of their lives.
                        </motion.div>
                    </div>
                </Row>
                <Row className='new-section'>
                    <Col className="d-flex justify-content-center position-relative">
                        <div className='arrowsDown'>
                            <motion.img src={arrow1} alt='arrow'
                                animate={{ opacity: [0, 1, 0] }}
                                transition={{ duration: 1.5, repeat: Infinity, ease: "easeOut" }}
                            />
                            <motion.img src={arrow2} alt='arrow'
                                animate={{ opacity: [0, 1, 0] }}
                                transition={{ duration: 1.5, delay: 0.2, repeat: Infinity, ease: "easeOut" }}
                            />
                            <motion.img src={arrow3} alt='arrow'
                                animate={{ opacity: [0, 1, 0] }}
                                transition={{ duration: 1.5, delay: 0.4, repeat: Infinity, ease: "easeOut" }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
