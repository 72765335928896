import React from 'react'
import { Container } from 'react-bootstrap'
import circleImg from './images/Circle.svg'
import './style.css'
import { motion } from "framer-motion"

export default function InfoSection() {
    return (
        <section className='new-section'>
            <div id='info-container'>
                <h3 className='text-center' id='info-container-heading'>We set-out to create a new financial system based on:</h3>
                <div className='new-section info-circle'>
                    <div className='position-relative info-cirlce-text-box p-0' id='movement'>
                        <motion.div
                            initial={{ opacity: 0, scale: 0 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                        >
                            <div className='circle-logo-advantage d-flex align-items-center justify-content-center'>
                                <img src={circleImg} alt='circle' className='circle-image rotateLeft' />
                                <div className='position-absolute info-circle-text blur'></div>
                                <div className='position-absolute info-circle-text'>
                                    <h4 className='section-heading-secondary d-flex align-items-center justify-content-center'>Movement</h4>
                                    <p className='section-text mb-0 body7'>
                                        Free movement of value across space and time
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    <div className='position-relative info-cirlce-text-box p-0' id='storage'>
                        <motion.div
                            initial={{ opacity: 0, scale: 0 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay:0.2 }}
                        >
                            <div className='circle-logo-advantage d-flex align-items-center justify-content-center'>
                                <img src={circleImg} alt='circle' className='circle-image rotateRight' />
                                <div className='position-absolute info-circle-text blur'></div>
                                <div className='position-absolute info-circle-text'>
                                    <h4 className='section-heading-secondary d-flex align-items-center justify-content-center'>Storage</h4>
                                    <p className='section-text mb-0 body7'>
                                        You truly own your money - no–one has the power to take it away from you
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    <div className='position-relative info-cirlce-text-box p-0' id='power'>
                        <motion.div
                            initial={{ opacity: 0, scale: 0 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay:0.4  }}
                        >
                            <div className='circle-logo-advantage d-flex align-items-center justify-content-center'>
                                <img src={circleImg} alt='circle' className='circle-image rotateLeft' />
                                <div className='position-absolute info-circle-text blur'></div>
                                <div className='position-absolute info-circle-text'>
                                    <h4 className='section-heading-secondary d-flex align-items-center justify-content-center'>Value</h4>
                                    <p className='section-text mb-0 body7'>
                                        No loss of value - purchasing power never wanes
                                    </p>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    )
}
