import React from 'react'
import { Row } from 'react-bootstrap'
import './style.css'
import BottomImage from './BottomImage'
import { motion } from 'framer-motion'

export default function PSSection() {
    return (
        <section className='new-section'>
            <Row className='justify-content-center'>
                <div className='polygon'>
                    <div className='section-text-primary'>
                        <Row className='d-flex justify-content-center'>
                            <div className='ps-text'>
                                <div className='body4'>
                                    <span className='ps-section'>P.S.</span> Looking for fellow-dreamers (and doers) who are not afraid of making bold moves and changes on a global scale.
                                </div>
                            </div>
                        </Row>
                        <div className='mt-text-ps ps-link d-flex justify-content-center'>
                            <a href='#leads' className='ppsbutton'>
                                <span className='section-link-text body3 d-flex align-items-start'>
                                    LET'S CONNECT?
                                    <span className='ms-4 arrowDownPs'>
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <line y1="-1.5" x2="19" y2="-1.5" transform="matrix(1 0 0 -1 0 16)" stroke="#B5B8BD" strokeWidth="3" />
                                            <line y1="-1.5" x2="19" y2="-1.5" transform="matrix(0 1 1 0 19 0)" stroke="#B5B8BD" strokeWidth="3" />
                                            <line y1="-1.5" x2="21" y2="-1.5" transform="matrix(0.707107 0.707107 0.707107 -0.707107 2.84375 0.765625)" stroke="#B5B8BD" strokeWidth="3" />
                                        </svg>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </Row>
            <motion.div className='d-flex justify-content-center'
                initial={{ scale: 0, y:-300, opacity:0.5 }}
                whileInView={{ scale: 1, y:0, opacity:1  }}
                viewport={{ once: true }}
                transition={{ duration: 1}}
            >
                <BottomImage className='psBottomImage' />
            </motion.div>
        </section>
    )
}
