export const plans = [
    {
        id:1,
        title: 'Gitex Global ( future blockchain summit)',
        dateText: 'October 10-14, 2022',
        date: '2022-10-14',
        place: 'Dubai World Trade Center',
        location: 'Dubai',
        link: 'https://gitex.com/',
        linkText: 'gitex.com',
        image: 'https://gitex.com/contents/Theme-2/images/banner5new.jpg'
    },
    {
        id:2,
        title: 'Blockchain dubai summit',
        dateText: 'October 14-15, 2022',
        date: '2022-10-15',
        place: 'GRAND HYATT DUBAI',
        location: 'Dubai',
        link: 'https://vertexgroup.ae/event/blockchaindubai2022/',
        linkText: 'vertexgroup.ae',
        image: 'https://vertexgroup.ae/event/blockchaindubai2022/wp-content/uploads/2022/07/blockchain.jpg'
    },
    {
        id:3,
        title: 'Sigma Europe',
        dateText: 'November 14-18, 2022',
        date: '2022-11-18',
        place: 'Malta Fairs and Convention Centre (MFCC)',
        location: 'Malta',
        link: 'https://sigma.world/europe/',
        linkText: 'sigma.world',
        image: 'https://sigma.world/wp-content/uploads/2022/08/Background-with-element-2.png'
    },
    {
        id:4,
        title: 'ICE London',
        dateText: 'February 7-9, 2023',
        date: '2023-02-09',
        place: 'ExCel London',
        location: 'England',
        link: 'https://www.icelondon.uk.com/',
        linkText: 'icelondon.uk.com',
        image: 'https://uplatform.com/wp-content/uploads/Events-preview-mini-2-.png'
    },
    {
        id:5,
        title: 'iGB Affiliate',
        dateText: 'February 8-11, 2023',
        date: '2023-02-11',
        place: 'ExCel London',
        location: 'England',
        link: 'https://www.igbaffiliate.com/',
        linkText: 'igbaffiliate.com',
        image: 'https://www.igbaffiliate.com/wp-content/uploads/sites/3/2021/04/AFFILIATE-LONDON.png'
    },
    {
        id:6,
        title: 'TES Affiliate Conferences',
        dateText: 'February 22-25, 2023',
        date: '2023-02-25',
        place: 'The Oitavos Hotel, Cascais',
        location: 'Portugal',
        link: 'https://tesaffiliateconferences.com/',
        linkText: 'tesaffiliateconferences.com',
        image: 'https://www.coinpoint.net/wp-content/uploads/2018/06/TES-Affiliate.png'
    },
    {
        id:7,
        title: 'Sigma Eurasia',
        dateText: 'March 13-16, 2023',
        date: '2023-03-16',
        place: 'InterContinental Dubai - Festival City, an IHG Hotel',
        location: 'Dubai',
        link: 'https://sigmaworld.events/sigma-eurasia-2023',
        linkText: 'sigmaworld.events',
        image: 'https://sigmaworld.events/img/landing-page/sigma-eurasia-logo.png'
    },
    {
        id:8,
        title: 'CasinoBeats Summit (Spring iGaming Week)',
        dateText: 'March 21-23, 2023',
        date: '2023-03-23',
        place: 'InterContinental Malta, an IHG Hotel',
        location: 'Malta',
        link: 'https://sbcevents.com/casinobeats-summit/',
        linkText: 'sbcevents.com',
        image: 'https://resources.gamblingtv.com/gamblingtv/2022/11/CBS23-GENERIC-BANNER-1024x512px.png'
    },
    {
        id:9,
        title: 'Sigma Europe',
        dateText: 'November 14-18, 2023',
        date: '2023-11-28',
        place: 'Malta Fairs and Convention Centre (MFCC)',
        location: 'Malta',
        link: 'https://sigma.world/europe/',
        linkText: 'sigma.world',
        image: 'https://agbrief.com/wp-content/uploads/2021/08/SiGMA-Europe.webp'
    },
]
