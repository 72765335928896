import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../images/logo.svg'
import { Col } from 'react-bootstrap'
import './style.css'
import React, { useState, useEffect } from 'react';
import { useDimensions } from "./use-dimensions";
import { motion, useCycle } from "framer-motion"
import { useRef } from "react";
import { Link, useParams, useLocation } from 'react-router-dom';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function BasicExample(props) {
  const { width } = useWindowDimensions();
  const [navbarStatus, changeStatus] = useCycle(false, true);
  const [screenWidth, screenStatus] = useState(false);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [currentLink, SetNewLink] = useState(props.activeLink)
  let {pathname} = useLocation();
  pathname = pathname.substring(1).split('/')[0];
  const sidebar = {
    open: {
      x: 0,
      transition: {
        duration: 1,
        x: 300
      }
    },
    closed: {
      x: 300,
      transition: {
        duration: 2,
        x: 300
      }
    }
  };
  const overlay = {
    open: {
      display: 'block',
      transition: {
        duration: 1,
        display: 'none',
      }
    },
    closed: {
      display: 'none',
      transition: {
        duration: 2,
        display: 'block',
      }
    }
  }
  useEffect(() => {
    if (width >= 992) {
      screenStatus(true)
    } else {
      screenStatus(false)
    }
  }, [width])

  return (
    <>
      {screenWidth ? (
        <Navbar fixed="top">
          <Container className='overflow-hidden'>
            <Link to="/" onClick={() => SetNewLink('')}>
              <img src={logo} alt="banx21" className='logo-header' />
            </Link>
            <Navbar.Collapse className="justify-content-end">
              <Link as={Nav.Link} className={`mr-link ${pathname === 'manifesto' ? ('active') : null}`} to="/manifesto" data-text="manifesto">manifesto</Link>
              <Link className={`mr-link ${pathname === 'events' ? ('active') : pathname === 'event' ? ('active') : null}`} to="/events" data-text="company events">company events</Link>
              <a className={`${pathname === 'careers' ? ('active') : null}`} href="https://libernetix.freshteam.com/jobs" target="_blank" data-text="careers">careers</a>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        <motion.nav expand="md" fixed="top" className='navbar navbar-expand-md navbar-light fixed-top'
          initial={false}
          animate={navbarStatus ? "open" : "closed"}
          custom={height}
          ref={containerRef}
        >
          <motion.div variants={overlay} className='overlay'></motion.div>
          <Container>
            <Link to=''>
              <img src={logo} alt="banx21" className='logo-header' />
            </Link>
            <div className='ms-auto nav-icon' onClick={() => changeStatus(!navbarStatus)}>
              <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H25C25.2652 0 25.5196 0.105357 25.7071 0.292893C25.8946 0.48043 26 0.734784 26 1C26 1.26522 25.8946 1.51957 25.7071 1.70711C25.5196 1.89464 25.2652 2 25 2Z" fill="white" />
                <path d="M25 9H1C0.734784 9 0.48043 8.89464 0.292893 8.70711C0.105357 8.51957 0 8.26522 0 8C0 7.73478 0.105357 7.48043 0.292893 7.29289C0.48043 7.10536 0.734784 7 1 7H25C25.2652 7 25.5196 7.10536 25.7071 7.29289C25.8946 7.48043 26 7.73478 26 8C26 8.26522 25.8946 8.51957 25.7071 8.70711C25.5196 8.89464 25.2652 9 25 9Z" fill="white" />
                <path d="M25 16H1C0.734784 16 0.48043 15.8946 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15C0 14.7348 0.105357 14.4804 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14H25C25.2652 14 25.5196 14.1054 25.7071 14.2929C25.8946 14.4804 26 14.7348 26 15C26 15.2652 25.8946 15.5196 25.7071 15.7071C25.5196 15.8946 25.2652 16 25 16Z" fill="white" />
              </svg>
            </div>
            <motion.div variants={sidebar} className={`collapsed`}>
              <div className='close-icon nav-icon' onClick={() => { changeStatus(false) }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 5.99902L6 17.999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6 5.99902L18 17.999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <motion.div className='d-flex'>
                <Col className='flex-column'>
                  <div className='link'>
                    <Link to="/manifesto" className={`${pathname === 'manifesto' ? ('active') : null}`}
                      onClick={() => {
                        setTimeout(() => {
                          changeStatus(false)
                        }, 100)
                      }}>
                      manifesto
                    </Link>
                  </div>
                  <div className='link'>
                    <Link to='events' className={`${pathname === 'events' ? ('active') : pathname === 'event' ? ('active') : null}`} onClick={() => {
                      setTimeout(() => {
                        changeStatus(false)
                      }, 100)
                    }}>
                      company events
                    </Link>
                  </div>
                  <div className='link'>
                    <a href="https://libernetix.freshteam.com/jobs" target="_blank" className={`${pathname === 'careers' ? ('active') : null}`} onClick={() => {
                      setTimeout(() => {
                        changeStatus(false)
                      }, 100)
                    }}>
                      careers
                    </a>
                  </div>
                </Col>
              </motion.div>
            </motion.div>
          </Container>
        </motion.nav>
      )
      }
    </>
  );
}

export default BasicExample;