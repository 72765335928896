export const ArrowLeft = () => {
    return (<svg width="102" height="24" viewBox="0 0 102 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.939339 13.045C0.353554 12.4592 0.353554 11.5095 0.939339 10.9237L10.4853 1.37777C11.0711 0.791986 12.0208 0.791986 12.6066 1.37777C13.1924 1.96356 13.1924 2.91331 12.6066 3.49909L4.12132 11.9844L12.6066 20.4697C13.1924 21.0554 13.1924 22.0052 12.6066 22.591C12.0208 23.1768 11.0711 23.1768 10.4853 22.591L0.939339 13.045ZM102 13.4844H2V10.4844H102V13.4844Z" fill="currentColor" />
    </svg>)
}

export const ArrowRight = () => {
    return (
        <svg width="102" height="24" viewBox="0 0 102 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M101.061 13.0607C101.646 12.4749 101.646 11.5251 101.061 10.9393L91.5147 1.3934C90.9289 0.807611 89.9792 0.807611 89.3934 1.3934C88.8076 1.97919 88.8076 2.92893 89.3934 3.51472L97.8787 12L89.3934 20.4853C88.8076 21.0711 88.8076 22.0208 89.3934 22.6066C89.9792 23.1924 90.9289 23.1924 91.5147 22.6066L101.061 13.0607ZM0 13.5H100V10.5H0V13.5Z" fill="currentColor" />
        </svg>
    )
}
