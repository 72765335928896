import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import frame1 from './images/Circle_frame_Sasha.svg'
import frame2 from './images/Circle_frame_Kostya.svg'
import './style.css'
import { motion } from 'framer-motion'
import founders from './images/founders.jpg'

export default function Leads() {
    return (
        <section className='section-margin-big' id='leads'>
            <Container>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <h2 className='section-heading-big'>Founders</h2>
                    </Col>
                </Row>
                <div className='d-flex justify-content-center'>
                    <div className='founders-container'>
                        <div className='founders-image d-flex justify-content-center'>
                            <img src={founders} alt="Founders - Alex and Konstantin" className='img-fluid' />
                        </div>
                        <div className='founders-descriptions'>
                            <div className='founder-info d-flex founder-1'>
                                <div className='founder-details'>
                                    <div className='founder-name'>
                                        <h6>Konstantin Margolis</h6>
                                    </div>
                                    <div className='founder-socials d-flex justify-content-center'>
                                        <a href='https://www.linkedin.com/in/konstantin-margolis-ba4b35134/' className='social-btn'>
                                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.78261 2.39129C4.78261 3.73042 3.73043 4.7826 2.3913 4.7826C1.05217 4.7826 0 3.73042 0 2.39129C0 1.05217 1.05217 -7.62939e-06 2.3913 -7.62939e-06C3.73043 -7.62939e-06 4.78261 1.05217 4.78261 2.39129ZM4.78261 6.69564H0V22H4.78261V6.69564ZM12.4348 6.69564H7.65217V22H12.4348V13.9652C12.4348 9.46955 18.1739 9.08694 18.1739 13.9652V22H22.9565V12.3391C22.9565 4.7826 14.4435 5.06955 12.4348 8.79998V6.69564Z"  fill="currentColor"/>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className='founder-description'>
                                        <p className='body8'>
                                            Multiple start-up failed <span>entrepreneur</span>. Dreamer in 20s, dreamer & doer in 30s. Constant learner - 100 books a year at a time. Building a <span>new financial system</span> one brick at a time. Building a top team to take on the world challenges. Always moving, exploring, connecting.
                                            <br />
                                            <span>Let’s talk?</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='founder-info d-flex founder-2'>
                                <div className='founder-details'>
                                    <div className='founder-name'>
                                        <h6>Alex Savy</h6>
                                    </div>
                                    <div className='founder-socials d-flex justify-content-center'>
                                        <a href='https://twitter.com/AlexanderSavy' className='social-btn me-3'>
                                            <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.9703 4.57573L21.7495 4.73407L21.7622 5.00543C21.772 5.21337 21.7764 5.42298 21.7764 5.63472C21.7764 12.0873 16.8281 19.5 7.79938 19.5C5.7476 19.5 3.80208 19.0589 2.04855 18.2703C4.22359 18.1045 6.21871 17.2962 7.84004 16.0354L8.95693 15.167L7.54235 15.1408C5.81953 15.109 4.32927 14.1367 3.57134 12.7225C3.62604 12.7241 3.68101 12.725 3.73623 12.725C4.24531 12.725 4.73881 12.6568 5.20801 12.5299L5.17492 11.5568C3.31339 11.1869 1.86198 9.6987 1.55535 7.83422C2.09769 8.03058 2.67965 8.1463 3.28576 8.1648L5.04177 8.21838L3.57713 7.24819C2.34448 6.43167 1.53659 5.03852 1.53659 3.46222C1.53659 2.88498 1.64553 2.33289 1.84373 1.8246C4.4246 4.65589 8.08941 6.4946 12.1885 6.69891L12.8521 6.73199L12.7002 6.08516C12.6222 5.75354 12.5817 5.40681 12.5817 5.04835C12.5817 2.5411 14.6329 0.5 17.1707 0.5C18.4915 0.5 19.6839 1.05362 20.5218 1.9388L20.7109 2.13861L20.9809 2.08578C21.6787 1.94925 22.3524 1.74574 22.9947 1.48233C22.6288 1.98541 22.1615 2.41202 21.6224 2.73277L21.937 3.65898C22.4095 3.60296 22.8726 3.51699 23.3243 3.40258C22.9102 3.83348 22.4566 4.22691 21.9703 4.57573Z" stroke="currentColor" />
                                            </svg>
                                        </a>
                                        <a href='https://www.linkedin.com/in/alexandersavy/' className='social-btn'>
                                            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.78261 2.39129C4.78261 3.73042 3.73043 4.7826 2.3913 4.7826C1.05217 4.7826 0 3.73042 0 2.39129C0 1.05217 1.05217 -7.62939e-06 2.3913 -7.62939e-06C3.73043 -7.62939e-06 4.78261 1.05217 4.78261 2.39129ZM4.78261 6.69564H0V22H4.78261V6.69564ZM12.4348 6.69564H7.65217V22H12.4348V13.9652C12.4348 9.46955 18.1739 9.08694 18.1739 13.9652V22H22.9565V12.3391C22.9565 4.7826 14.4435 5.06955 12.4348 8.79998V6.69564Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                    </div>
                                    <div className='founder-description'>
                                        <p className='body8'>
                                            CEO, co-founder of <span>Metamouse</span> and <span>Altercap</span>. Launched Bitsugar.com in 2018 - crypto loans and Altercap.com in 2020 - crypto broker.
                                            <br />
                                            <br />
                                            Executed  <span>crypto card project in 2016</span>  at Payment Institution. Bought <span>first BTC in 2015</span>, still hodling.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}
