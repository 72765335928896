import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import './styleEvents.css'
import background1 from './images/background1.svg'
import Eventgrid from './Eventgrid'
import Visiting from './Visiting'
import { Helmet } from 'react-helmet'

export default function Events() {
    const [section, setSection] = useState('events')
    return (
        <>
            <Helmet>
                <title>Banx21 - Company Events</title>
                <meta name="description" content="We like to spend time actively. Here you can see how our events. Also take part in a future events with us." />
            </Helmet>
            <section id='events'>
                <Container className='overflow-inferit'>
                    <div className='heading position-relative'>
                        <h1>Our events</h1>
                        <img src={background1} alt='background' className='background1' />
                    </div>
                    <div className='manifesto-description'>
                        <p className='body1'>
                            We like to spend time actively. Here you can see how our events. Also take part in a future events with us!
                        </p>
                    </div>
                    <div className='choose-events-btn'>
                        <div className={`event-btn body7 ${section === 'events' ? ('active') : (null)}`} onClick={() => setSection('events')}>Company events</div>
                        <div className={`event-btn body7 ${section === 'plans' ? ('active') : (null)}`} onClick={() => setSection('plans')}>We are going to visit</div>
                    </div>
                    {section === 'events' ? (
                        <Eventgrid />
                    ) : (
                        <Visiting />
                    )}
                </Container>
            </section>
        </>
    )
}
